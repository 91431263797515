<template>
    <div class="body">
        <div class="container" v-if="info">
            <div class="ret-sumary">
                <img class="ret-log" :src="getImg(info.score)" alt="" srcset="">
                <h1 class="score">{{info.score}} <span class="unit">分</span></h1>
            </div>
            
            <div class="question-desc">
                <div class="row-1">
                    <div class="item">
                        <div class="row-1-top h2">{{format(info.use_times)}}</div>
                        <span class="row-1-bottom row-font">考试用时</span>
                    </div>
                    <div class="item">
                        <div class="row-1-top h2">100分</div>
                        <span class="row-1-bottom row-font">总分</span>
                    </div>
                    <div class="item">
                        <div class="row-1-top h2">{{info.order}}</div>
                        <span class="row-1-bottom row-font">当前排名</span>
                    </div>
                </div>
                <div class="row-line">
                    
                </div>
                <div class="row-2 row-font">
                    <div class="item">
                        答对{{info.statistics.correct_num}}道题
                    </div>
                    <div class="item">
                        正确率{{info.statistics.correct_rate}}%
                    </div>
                    <div class="item">
                        及格60分
                    </div>
                </div>
            </div>
            <a class="to-detail" @click="toDetail">查看详情</a>
        </div>
        <!-- <div class="footer">
            <van-button type="info" @click="toQuestion">重考</van-button>
        </div> -->
    </div>
</template>

<script>
    import { getExamResult, getCoursewareExamResult } from '../../api/task.js';
    import {formatSecond, checkLogin} from '../../utils/common.js';
    import { mapState } from 'vuex';
    var examid, tid, type;
    export default{
        activated() {
            examid = this.$route.query.examid; // 课程id
            tid = this.$route.query.tid; // 任务ID，用于计算进度，并更新总进度 
            type = this.$route.query.type;
            checkLogin().then(()=> {
                this.getResult();
            })
            this.hideTab(1);
        },
        data() {
          return {
              info: null,
              total: 100,
              passed: 60,
          }  
        },
        computed: mapState(['token']),
        methods:{
            getResult(){
                var fc = type =='c' ? getCoursewareExamResult : getExamResult;
                fc(tid, examid).then((ret)=> {
                    if(ret){
                        this.info = ret.data;
                    }
                })
                
            },
            toDetail(){
                this.$router.push(`/result-detail?examid=${examid}`);
                console.log('xiangq');
            },
            toQuestion(){
                 this.$router.push('/question');
            },
            getImg(score){
                if(score>= this.passed){
                    return require('../../assets/images/passed.jpg');
                }else{
                    return require('../../assets/images/unpassed.jpg');
                }
            },
            format(s){
             return formatSecond(s);
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.remove('tab-hide');
            }
        }
    }
</script>

<style scoped>
    .container{
        text-align: center;
    }
    .ret-sumary{
        margin-top: 3rem;
        text-align: center;
    }
    .ret-log{
        width: 15.4rem;
        height: 17.1rem;
    }
    .score{
        color: #fb4a4a;
        font-size: 3rem;
    }
    .score .unit{
        font-size: 2rem;
    }
    .question-desc{
        background-color: #f7f8fa;
        padding: 1.2rem 0 2rem;
        border-radius: 5px;
    }
    .question-desc .item{
        width: 33.3333%;
        text-align: center;
    }
    .question-desc .h2{
        font-size: 2.6rem;
        /* font-weight: bold; */
    }
    .row-1{
        display: flex;
    }
    .row-2{
        display: flex;
    }
    .row-font{
        font-size: 16px;
        color: #666;
    }
    .row-2 .item{
        height: 2rem;
        line-height: 2rem;
    }
    .row-2 .item:nth-child(2){
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }
    .row-line{
        width: 30.1rem;
        height: 1px;
        background-color: #ccc;
        margin: 15px auto 10px;
    }
    .to-detail{
        display: inline-block;
        margin: 20px auto;
        color: #465ecc;
        font-size: 16px;
    }
    .footer{
        position: fixed;
        width: 100vw;
        height: 4.5rem;
        bottom: 0;
        border-top: 1px solid #e8e8e8;
    }
    .footer button{
        margin-top: 0.5rem;
        height: 3.5rem;
        width: 34.5rem;
        line-height: 3.5rem;
        font-size: 1.4rem;
        background-color: #465ecc;
        margin-left: 1.5rem;
    }
    /* .van-tabbar--fixed{
        display:none!important;
    } */
</style>